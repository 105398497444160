import 'bootstrap/dist/js/bootstrap.js'
import "bootstrap-icons/font/bootstrap-icons.css";

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

import 'mapbox-gl/dist/mapbox-gl.css';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { initializeApp } from 'firebase/app'
import { getAuth, onIdTokenChanged } from 'firebase/auth'

// Initialize font awesome icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(far)
library.add(fab)
library.add(fas)

// Initialize Firebase
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
}
initializeApp(firebaseConfig);

import apiUrl from '@/apiUrl'
import './registerServiceWorker'

console.log("App running in:")
console.log(process.env.NODE_ENV)

// Initialize app only when the user auth state is loaded to prevent routing without user
let app
onIdTokenChanged(getAuth(), user => {
  // Load in the user - make access token and claims easily accessible 
  store.dispatch('setUser', user)
  if (!app) {
    app = createApp(App)
    app.component('EasyDataTable', Vue3EasyDataTable);
    app.component('FontAwesomeIcon', FontAwesomeIcon)
    app.config.globalProperties.$apiUrl = apiUrl
    app.config.globalProperties.$firebaseProject = process.env.VUE_APP_FIREBASE_PROJECT_ID
    app.use(store).use(router).mount('#app')
  }
})
