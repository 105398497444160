<template>
  <TopBar/>
  <router-view/>
</template>

<script>

import TopBar from '@/components/other/TopBar.vue'

export default {
  components: {
    TopBar
  }
}
</script>

<style lang="scss">
</style>
