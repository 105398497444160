
import storeHelpers from './helpers'

export const reports = {
  namespaced: true,
  state: {
    reports: {},
    reportsSubscription: null
  },
  mutations: {
    setReportsById (state, data) {
      storeHelpers.setNameById(state, data, 'reports')
    },
    setReportsSubscription (state, data) {
      storeHelpers.setNameSubscription(state, data, 'reports')
    },
  },
  actions: {
    subscribeReports ({ commit, state }) {
      storeHelpers.subscribeByName(commit, state, 'reports')
    },  
    unsubscribeReports ({ commit, state }) {
      storeHelpers.unsubscribeByName(commit, state, 'reports')
    }
  }
}
