
import storeHelpers from './helpers'
import apiUrl from '../apiUrl'

export const results = {
  namespaced: true,
  state: {
    results: {},
    resultsSubscription: null,
    resultTraces: {},
    batchResultTraces: {},
    replayResultTraces: {}
  },
  mutations: {
    setResultsById (state, data) {
      storeHelpers.setNameById(state, data, 'results')
    },
    setResultsSubscription (state, data) {
      storeHelpers.setNameSubscription(state, data, 'results')
    },
    setResultTraces (state, data) {
      state.resultTraces[data.id] = data.data
    },
    setBatchResultTraces (state, data) {
      state.batchResultTraces[data.id] = data.data
    },
    setReplayResultTraces (state, data) {
      if (!Object.keys(state.replayResultTraces).includes(data.id)) {
        state.replayResultTraces[data.id] = {}
      }
      state.replayResultTraces[data.id][data.stageName] = data.data
    }
  },
  actions: {
    subscribeResults ({ commit, state }) {
      storeHelpers.subscribeByName(commit, state, 'results')
    },  
    unsubscribeResults ({ commit, state }) {
      storeHelpers.unsubscribeByName(commit, state, 'results')
    },
    fetchResultTraces ({ commit, state, rootState }, id) {
      fetch(apiUrl + '/result_traces/' + id + '/', {
        headers: {
          authorization: 'Bearer ' + rootState.userAccessToken
        }
      })
        .then(response => response.json())
        .then (data => {
          commit('setResultTraces', {
            id: id,
            data: data
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    fetchBatchResultTraces ({ commit, state, rootState }, id) {
      fetch(apiUrl + '/batch_result_traces/' + id + '/', {
        headers: {
          authorization: 'Bearer ' + rootState.userAccessToken
        }
      })
        .then(response => response.json())
        .then (data => {
          commit('setBatchResultTraces', {
            id: id,
            data: data
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    fetchReplayResultTraces ({ commit, state, rootState }, { resultId, stageName }) {
      fetch(apiUrl + '/replay_result_traces/' + resultId + '/' + stageName + '/', {
        headers: {
          authorization: 'Bearer ' + rootState.userAccessToken
        }
      })
        .then(response => response.json())
        .then (data => {
          commit('setReplayResultTraces', {
            id: resultId,
            stageName: stageName,
            data: data
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
