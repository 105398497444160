
import storeHelpers from './helpers'

export const live = {
  namespaced: true,
  state: {
    liveSetups: {},
    liveSetupsSubscription: null
  },
  mutations: {
    setLiveSetupsById (state, data) {
      storeHelpers.setNameById(state, data, 'liveSetups')
    },
    setLiveSetupsSubscription (state, data) {
      storeHelpers.setNameSubscription(state, data, 'liveSetups')
    },
  },
  actions: {
    subscribeLiveSetups ({ commit, state }) {
      storeHelpers.subscribeByName(commit, state, 'liveSetups')
    },  
    unsubscribeLiveSetups ({ commit, state }) {
      storeHelpers.unsubscribeByName(commit, state, 'liveSetups')
    }
  }
}
