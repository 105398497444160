
import storeHelpers from './helpers'

export const wheels = {
  namespaced: true,
  state: {
    wheels: {},
    wheelsSubscription: null
  },
  mutations: {
    setWheelsById (state, data) {
      storeHelpers.setNameById(state, data, 'wheels')
    },
    setWheelsSubscription (state, data) {
      storeHelpers.setNameSubscription(state, data, 'wheels')
    },
  },
  actions: {
    subscribeWheels ({ commit, state }) {
      storeHelpers.subscribeByName(commit, state, 'wheels')
    },  
    unsubscribeWheels ({ commit, state }) {
      storeHelpers.unsubscribeByName(commit, state, 'wheels')
    }
  }
}
