/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready (registration) {
      console.log(
        'Ready V6 - App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
      console.log(registration)
    },
    registered (registration) {
      console.log("Service worker has been registered.")
      console.log(registration)
    },
    cached (registration) {
      console.log('Content has been cached for offline use.')
      console.log(registration)
      console.log('Try to update?')
      registration.update()
      //alert("Content has been cached for offline use.")
    },
    updatefound (registration) {
      console.log('New content is downloading.')
      console.log(registration)
      //alert("New update found - downloading")
      //while (!confirm("Refresh")) {}
      //window.location.reload()
    },
    updated (registration) {
      console.log('Updated event')
      console.log(registration)
      if (!registration || !registration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
