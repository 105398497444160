<template>
  <div id="topbar-sws" class="sticky-top">
    <div v-if="screenWidth > breakWidth" class="w-100" style="z-index: 1000;">
      <div class="topbar topbar-container topbar-container-between">
        <div class="topbar-container topbar-container-left">
          <img class="topbar-image" src="@/assets/logo.png" alt="" @click="$router.push({name: 'home'}); showDropdown = false">
          <div v-if="$store.state.user" class="topbar-container topbar-container-left">
            <button class="btn btn-sm" :class="[isInSimClick ? 'btn-outline-primary' : 'btn-outline-light']" type="button" @click="showDropdown = !showDropdown">
              <b>Simulation</b>
            </button>
            <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/riders" @click="showDropdown = false">Riders</router-link>
            <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/wheels" @click="showDropdown = false">Wheels</router-link>
            <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/courses" @click="showDropdown = false">Courses</router-link>
            <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/reporting" @click="showDropdown = false">Reporting</router-link>
            <router-link v-if="userClaims.includes('vp-test-features')" class="topbar-link" to="/live" @click="showDropdown = false">LIVE <i class="bi bi-car-front"></i></router-link>
          </div>
          <div v-else class="topbar-container topbar-container-left">
            <router-link class="topbar-link" to="/login">Login</router-link>
          </div>
        </div>
        <div v-if="$store.state.user" class="topbar-container topbar-container-right">
          <button class="btn btn-dark ms-1" @click="toggleDarkmode">
            <i v-if="$store.state?.inDarkMode" class="bi bi-sun"></i>
            <i v-else class="bi bi-moon"></i>
          </button>
          <router-link v-if="userClaims.includes('vp-admin')" class="topbar-link" to="/admin">Admin</router-link>
          <img v-if="$store.state.user.photoURL !== null" class="rounded-circle topbar-image" :src="$store.state.user.photoURL" @click="signOut">
          <img v-else class="rounded-circle topbar-image" src="@/assets/igd.jpeg" alt="" @click="signOut">
        </div>
      </div>
      <div v-if="showDropdown" class="topbar-dropdown topbar-container topbar-container-top">
          <hr>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/simulation/setup" @click="showDropdown = false">Simulation setup</router-link>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/simulation/results" @click="showDropdown = false">Simulation results</router-link>
          <hr>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/replay/setup" @click="showDropdown = false">Replay setup</router-link>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/replay/results" @click="showDropdown = false">Replay results</router-link>
          <hr>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/batch/setup" @click="showDropdown = false">Batch setup</router-link>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/batch/results" @click="showDropdown = false">Batch results</router-link>
          <hr>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/tasks" @click="showDropdown = false">Tasks</router-link>
      </div>
    </div>
    <div v-else class="w-100" style="z-index: 1000;">
      <div class="topbar topbar-container topbar-container-between">
        <div class="topbar-container topbar-container-left">
          <img class="topbar-image" src="@/assets/logo-small.png" alt=""  @click="$router.push({name: 'home'}); showDropdown = false">
          <button v-if="$store.state.user" class="btn btn-outline-primary btn-sm" type="button" @click="showDropdown = !showDropdown">
            <b>Menu</b>
          </button>
          <router-link v-else class="topbar-link" to="/login">Login</router-link>
        </div>
        <div v-if="$store.state.user" class="topbar-container topbar-container-right">
          <button class="btn btn-dark ms-1" @click="toggleDarkmode">
            <i v-if="$store.state.inDarkMode" class="bi bi-sun"></i>
            <i v-else class="bi bi-moon"></i>
          </button>
          <router-link v-if="userClaims.includes('vp-admin')" class="topbar-link" to="/admin">Admin</router-link>
          <img v-if="$store.state.user.photoURL !== null" class="rounded-circle topbar-image" :src="$store.state.user.photoURL" @click="signOut">
          <img v-else class="rounded-circle topbar-image" src="@/assets/igd.jpeg" alt="" @click="signOut">
        </div>
      </div>
      <div v-if="showDropdown" class="topbar-dropdown topbar-container topbar-container-top">
          <hr>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/simulation/setup" @click="showDropdown = false">Simulation setup</router-link>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/simulation/results" @click="showDropdown = false">Simulation results</router-link>
          <hr>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/replay/setup" @click="showDropdown = false">Replay setup</router-link>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/replay/results" @click="showDropdown = false">Replay results</router-link>
          <hr>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/batch/setup" @click="showDropdown = false">Batch setup</router-link>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/batch/results" @click="showDropdown = false">Batch results</router-link>
          <hr>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/tasks" @click="showDropdown = false">Tasks</router-link>
          <hr>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/riders" @click="showDropdown = false">Riders</router-link>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/wheels" @click="showDropdown = false">Wheels</router-link>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/courses" @click="showDropdown = false">Courses</router-link>
          <router-link v-if="userClaims.includes('vp-user')" class="topbar-link" to="/reporting" @click="showDropdown = false">Reporting</router-link>
          <hr>
          <router-link v-if="userClaims.includes('vp-test-features')" class="topbar-link" to="/live" @click="showDropdown = false">LIVE <i class="bi bi-car-front"></i></router-link>
      </div>
    </div>
  </div>
</template>

<script>

import { getAuth, signOut } from 'firebase/auth'

export default {
  name: 'TopBar',
  components: {
  },
  data () {
    return {
      showDropdown: false,
      screenWidth: null,
      breakWidth: 900,
      observer: null
    }
  },
  computed: {
    userClaims () {
      if (this.$store.state.userClaims) {
        return Object.keys(this.$store.state.userClaims)
      } else {
        return []
      }
    },
    isInSimClick () {
      if (this.screenWidth <= this.breakWidth) {
        return true
      } else {
        if (['simulationSetup', 'simulationResults', 'replaySetup', 'replayResults', 'tasks'].includes(this.$route.name)) {
          return true
        } else {
          return false
        }
      }
    }
  },
  methods: {
    onResize () {
      this.screenWidth = window.innerWidth
    },
    signOut () {
      signOut(getAuth())
        .then(() => {
          this.$store.dispatch('setUser', null)
          this.$router.push({name: 'login'})
        })
        .catch((error) => {
        })
    },
    toggleDarkmode () {
      darkmode.toggleDarkMode()
      this.$store.dispatch('setDarkMode', darkmode.inDarkMode)
    }
  },
  mounted () {
    this.$store.dispatch('setDarkMode', darkmode.inDarkMode)
    this.screenWidth = window.innerWidth
    this.observer = new ResizeObserver(entries => {
      if (entries?.[0]?.contentRect?.width) this.screenWidth = entries?.[0]?.contentRect?.width
    })
    this.observer.observe(document.getElementById('topbar-sws'))
  }
}
</script>


<style scoped>
.topbar {
  height: 50px !important;
  background-color: black;
  padding: 0;
  margin: 0;
}
.topbar-container::-webkit-scrollbar {
  display: none;
}
.topbar-dropdown {
  position: absolute;
  top: 50px;
  background-color: black;
  width: 100vw;
  height: auto !important;
}
.topbar-container {
  height: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: visible;
  padding: 0;
  margin: 0;
}

.topbar-container-left {
  justify-self: left;
  flex-direction: row;
}
.topbar-container-left>*{
  margin-left: 0.5rem
}

.topbar-container-right {
  justify-self: right;
  flex-direction: row;
}
.topbar-container-right>*{
  margin-right: 0.5rem
}
.topbar-container-top {
  justify-self: top;
  flex-direction: column;
}
.topbar-container-top>*{
  margin: 0.25rem 0 0.25rem 0;
}

.topbar-container-between {
  justify-content: space-between;
}

.topbar-image {
  height: 75%;
  object-fit: scale-down;
}
.topbar-link {
  color: white;
  text-decoration: none;
}
.topbar-link:hover {
  color: darkred;
}
.router-link-active {
  color: #cc092f;
}
.fancy-text {
  color: pink;
}

img:hover {
  cursor: pointer;
}
hr {
  width: 100%;
  border-top: 1px solid #cc092f;
  opacity: 1.0;
}
</style>