import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      loginRequired: true
    },
    component: HomeView
  },{
    path: '/simulation/setup',
    name: 'simulationSetup',
    query: {},
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user']
    },
    component: function () {
      return import('../views/SimulationSetupView.vue')
    }
  },{
    path: '/simulation/results/:ids?',
    name: 'simulationResults',
    query: {},
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user']
    },
    component: function () {
      return import('../views/SimulationResultsView.vue')
    }
  },{
    path: '/simulation/result_sheet/:id',
    name: 'simulationResultSheet',
    query: {},
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user']
    },
    component: function () {
      return import('../views/SimulationResultSheet.vue')
    }
  },{
    path: '/batch/setup',
    name: 'batchSetup',
    query: {},
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user']
    },
    component: function () {
      return import('../views/BatchSetupView.vue')
    }
  },{
    path: '/batch/results/:id?',
    name: 'batchResults',
    query: {},
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user']
    },
    component: function () {
      return import('../views/BatchResultsView.vue')
    }
  },{
    path: '/replay/setup',
    name: 'replaySetup',
    query: {},
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user']
    },
    component: function () {
      return import('../views/ReplaySetupView.vue')
    }
  },{
    path: '/replay/results/:id?',
    name: 'replayResults',
    query: {},
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user']
    },
    component: function () {
      return import('../views/ReplayResultsView.vue')
    }
  },{
    path: '/tasks/:id?',
    name: 'tasks',
    query: {},
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user']
    },
    component: function () {
      return import('../views/TasksView.vue')
    }
  },{
    path: '/riders/:id?/:setupId?',
    name: 'riders',
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user']
    },
    component: function () {
      return import('../views/RidersView.vue')
    }
  },
  {
    path: '/wheels',
    name: 'wheels',
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user']
    },
    component: function () {
      return import('../views/WheelsView.vue')
    }
  },{
    path: '/course_create',
    name: 'course_create',
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user']
    },
    component: function () {
      return import('../views/CourseCreateView.vue')
    }
  },{
    path: '/courses/:id?',
    name: 'courses',
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user']
    },
    component: function () {
      return import('../views/CoursesView.vue')
    }
  },{
    path: '/course_notes/:id/:note_id?',
    name: 'course_notes',
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user']
    },
    component: function () {
      return import('../views/CourseNotesView.vue')
    }
  },{
    path: '/reporting/:id?',
    name: 'reporting',
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user']
    },
    component: function () {
      return import('../views/ReportingView.vue')
    }
  },{
    path: '/live/:id?',
    name: 'live',
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user']
    },
    component: function () {
      return import('../views/LiveView.vue')
    }
  },{
    path: '/sensors_demo',
    name: 'sensors_demo',
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user']
    },
    component: function () {
      return import('../views/SensorsDemoView.vue')
    }
  },{
    path: '/sensors_library',
    name: 'sensors_library',
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user']
    },
    component: function () {
      return import('../views/SensorsLibraryView.vue')
    }
  },{
    path: '/admin',
    name: 'admin',
    meta: {
      loginRequired: true,
      claimsRequired: ['vp-user', 'vp-admin']
    },
    component: function () {
      return import('../views/AdminView.vue')
    }
  },{
    path: '/login',
    name: 'login',
    component: function () {
      return import('../views/LoginView.vue')
    }
  },{
    path: '/register',
    name: 'register',
    component: function () {
      return import('../views/RegisterView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const routerLogging = false
function logWrap(message) {
  if (routerLogging) {
    console.log(message)
  }
}

router.beforeEach((to, from, next) => {

  logWrap('---------')
  logWrap(from.name + ' -> ' + to.name)

  var authenticated = false
  var authorized = false

  var user = store.state.user

  // Check if authenticated
  if (to.matched.some((record) => record.meta.loginRequired)) {
    logWrap('Login is required for this route')
    if (user !== null) {
      authenticated = true
    }
  } else {
    logWrap('Login is NOT required for this route')
    authenticated = true
  }

  // Check claims
  var claimsRequired = to.matched.filter(route => route.meta?.claimsRequired).map(route => route.meta?.claimsRequired).flat()
  if (claimsRequired.length) {
    logWrap('Claims required for this route')
    logWrap(claimsRequired)
    if (authenticated) { // Only check if authenticated - otherwise error
      if (claimsRequired.every((claim) => Object.keys(store.state.userClaims).includes(claim))) {
        authorized = true
      }
    }
  } else {
    logWrap('NO claim required for this route')
    authorized = true
  }
  
  logWrap('Check:')
  logWrap('Authenticated: ' + authenticated)
  logWrap('Authorized: ' + authorized)

  if (authenticated && authorized) {
    next()
  } else {
    next('/login')
  }

})

export default router
