
import storeHelpers from './helpers'
import apiUrl from '../apiUrl'

export const veloviewer = {
  namespaced: true,
  state: {
    veloviewer: {},
    veloviewerSubscription: null,
    veloviewerTraces: {}
  },
  mutations: {
    setVeloviewerById (state, data) {
      storeHelpers.setNameById(state, data, 'veloviewer')
    },
    setVeloviewerSubscription (state, data) {
      storeHelpers.setNameSubscription(state, data, 'veloviewer')
    },
    setVeloviewerTraces (state, data) {
      state.veloviewerTraces[data.id] = data.data
    }
  },
  actions: {
    subscribeVeloviewer ({ commit, state }) {
      storeHelpers.subscribeByName(commit, state, 'veloviewer')
    },  
    unsubscribeVeloviewer ({ commit, state }) {
      storeHelpers.unsubscribeByName(commit, state, 'veloviewer')
    },
    fetchVeloviewerTraces ({ commit, state, rootState }, id) {
      fetch(apiUrl + '/veloviewer_data/' + id + '/file/traces', {
        headers: {
          authorization: 'Bearer ' + rootState.userAccessToken
        }
      })
        .then(response => response.json())
        .then (data => {
          commit('setVeloviewerTraces', {
            id: id,
            data: data
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
