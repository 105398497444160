<template>
  <div class="container-fluid pt-2 pb-2">
    <div class="row">
      <div class="col">
        <div class="card" >
          <div class="card-header">
            <h2 class="card-title">Welcome {{$store.state.user?.displayName}}</h2>
          </div>
          <div class="card-body overflow-auto">
            <p v-if="$store.state.userClaims?.['vp-user'] === true" class="card-text">Use the menu above to access VP features</p>
            <p v-else class="card-text">You are not yet authorized to use VP.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {
  }
}
</script>
