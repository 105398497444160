
import storeHelpers from './helpers'

export const sensors = {
  namespaced: true,
  state: {
    sensors: {},
    sensorsSubscription: null
  },
  mutations: {
    setSensorsById (state, data) {
      storeHelpers.setNameById(state, data, 'sensors')
    },
    setSensorsSubscription (state, data) {
      storeHelpers.setNameSubscription(state, data, 'sensors')
    },
  },
  actions: {
    subscribeSensors ({ commit, state }) {
      storeHelpers.subscribeByName(commit, state, 'sensors')
    },  
    unsubscribeSensors ({ commit, state }) {
      storeHelpers.unsubscribeByName(commit, state, 'sensors')
    }
  }
}
