
import storeHelpers from './helpers'
import apiUrl from '../apiUrl'

export const riders = {
  namespaced: true,
  state: {
    riders: {},
    ridersSubscription: null,
    riderSetups: {},
    riderSetupsSubscriptions: {},
    riderRides: {},
    riderRidesSubscriptions: {},
    riderRideTraces: {},
  },
  mutations: {
    setRidersById (state, data) {
      storeHelpers.setNameById(state, data, 'riders')
    },
    setRidersSubscription (state, data) {
      storeHelpers.setNameSubscription(state, data, 'riders')
    },
    setRiderSetupsNestedById (state, data) {
      storeHelpers.setNameNestedById(state, data, 'riderSetups')
    },
    setRiderSetupsNestedSubscription (state, data) {
      storeHelpers.setNameNestedSubscription(state, data, 'riderSetups')
    },
    setRiderRidesNestedById (state, data) {
      storeHelpers.setNameNestedById(state, data, 'riderRides')
    },
    setRiderRidesNestedSubscription (state, data) {
      storeHelpers.setNameNestedSubscription(state, data, 'riderRides')
    },
    setRiderRideTraces (state, data) {
      if (data.riderId in state.riderRideTraces) {
        state.riderRideTraces[data.riderId][data.rideId] = data.data
      } else {
        state.riderRideTraces[data.riderId] = {
          [data.rideId]: data.data
        }
      }
    }
  },
  actions: {
    subscribeRiders ({ commit, state }) {
      storeHelpers.subscribeByName(commit, state, 'riders')
    },  
    unsubscribeRiders ({ commit, state }) {
      storeHelpers.unsubscribeByName(commit, state, 'riders')
    },
    subscribeRiderSetups ({ commit, state }, id) {
      storeHelpers.subscribeByNameNested(commit, state, ['riders', id, 'setups'], [id], 'riderSetups')
    },
    unsubscribeRiderSetups ({ commit, state }, id) {
      storeHelpers.unsubscribeByNameNested(commit, state, [id], 'riderSetups')
    },
    subscribeRiderRides ({ commit, state }, id) {
      storeHelpers.subscribeByNameNested(commit, state, ['riders', id, 'rides'], [id], 'riderRides')
    },
    unsubscribeRiderRides ({ commit, state }, id) {
      storeHelpers.unsubscribeByNameNested(commit, state, [id], 'riderRides')
    },
    fetchRiderRideTraces ({ commit, state, rootState }, {riderId, rideId}) {
      fetch(apiUrl + '/rider_data/' + riderId + '/rides/' + rideId + '/traces', {
        headers: {
          authorization: 'Bearer ' + rootState.userAccessToken
        }
      })
        .then(response => {
          return response.json()
        })
        .then (data => {
          commit('setRiderRideTraces', {
            riderId: riderId,
            rideId: rideId,
            data: data
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
  }
}
