
import { createStore } from 'vuex'

import apiUrl from '../apiUrl'

import { tasks } from './module.tasks'
import { wheels } from './module.wheels'
import { courses } from './module.courses'
import { riders } from './module.riders'
import { veloviewer } from './module.veloviewer'
import { results } from './module.results'
import { reports } from './module.reports'
import { sensors } from './module.sensors'
import { live } from './module.live'

export default createStore({
  state: {
    user: null,
    userClaims: null,
    userAccessToken: null,
    users: {},
    inDarkMode: false
  },
  getters: {
  },
  mutations: {
    setUser (state, user) {
      state.user = user
      if (user) {
        user.getIdTokenResult().then(idTokenResult => {
          state.userClaims = idTokenResult.claims
          state.userAccessToken = idTokenResult.token
        })
      } else {
        state.userClaims = null
        state.userAccessToken = null
      }
    },
    setUsers (state, data) {
      state.users = data
    },
    setDarkMode (state, inDarkMode) {
      state.inDarkMode = inDarkMode
    }
  },
  actions: {
    setUser ({ commit }, user) {
      commit('setUser', user)
      if (user) {
        fetch(apiUrl + '/user_data', {
          headers: {
            authorization: 'Bearer ' + user.accessToken
          }
        })
          .then(response => response.json())
          .then(users => {
            commit('setUsers', users)
          })
      }
    },
    setDarkMode ({ commit }, inDarkMode) {
      commit('setDarkMode', inDarkMode)
    }
  },
  modules: {
    tasks,
    wheels,
    courses,
    riders,
    veloviewer,
    results,
    reports,
    sensors,
    live
  }
})
