
import storeHelpers from './helpers'
import apiUrl from '../apiUrl'

export const courses = {
  namespaced: true,
  state: {
    courses: {},
    coursesSubscription: null,
    courseMarkers: {},
    courseMarkersSubscriptions: {},
    courseTraces: {}
  },
  mutations: {
    setCoursesById (state, data) {
      storeHelpers.setNameById(state, data, 'courses')
    },
    setCoursesSubscription (state, data) {
      storeHelpers.setNameSubscription(state, data, 'courses')
    },
    setCourseMarkersNestedById (state, data) {
      storeHelpers.setNameNestedById(state, data, 'courseMarkers')
    },
    setCourseMarkersNestedSubscription (state, data) {
      storeHelpers.setNameNestedSubscription(state, data, 'courseMarkers')
    },
    setCourseTraces (state, data) {
      state.courseTraces[data.id] = data.data
    }
  },
  actions: {
    subscribeCourses ({ commit, state }) {
      storeHelpers.subscribeByName(commit, state, 'courses')
    },  
    unsubscribeCourses ({ commit, state }) {
      storeHelpers.unsubscribeByName(commit, state, 'courses')
    },
    subscribeCourseMarkers ({ commit, state }, id) {
      storeHelpers.subscribeByNameNested(commit, state, ['courses', id, 'markers'], [id], 'courseMarkers')
    },
    unsubscribeCourseMarkers ({ commit, state }, id) {
      storeHelpers.unsubscribeByNameNested(commit, state, [id], 'courseMarkers')
    },
    fetchCourseTraces ({ commit, state, rootState }, id) {
      fetch(apiUrl + '/course_data/' + id + '/traces', {
        headers: {
          authorization: 'Bearer ' + rootState.userAccessToken
        }
      })
        .then(response => response.json())
        .then (data => {
          commit('setCourseTraces', {
            id: id,
            data: data
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
