
import storeHelpers from './helpers'

export const tasks = {
  namespaced: true,
  state: {
    tasks: {},
    tasksSubscription: null
  },
  mutations: {
    setTasksById (state, data) {
      storeHelpers.setNameById(state, data, 'tasks')
    },
    setTasksSubscription (state, data) {
      storeHelpers.setNameSubscription(state, data, 'tasks')
    },
  },
  actions: {
    subscribeTasks ({ commit, state }) {
      storeHelpers.subscribeByName(commit, state, 'tasks')
    },  
    unsubscribeTasks ({ commit, state }) {
      storeHelpers.unsubscribeByName(commit, state, 'tasks')
    }
  }
}
